// -----------------------------------------------------------------------------------------------------
@use "@angular/material" as mat;
@import "~@angular/material/theming"; // Gabri - Aggiunto per utilizzare $dark-primary-text e $light-primary-text

// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------
$fuse-white: (
  500: white,
  contrast: (500: $dark-primary-text,
  ),
);

$fuse-black: (
  500: black,
  contrast: (500: $light-primary-text,
  ),
);

$fuse-navy: (
  50: #ececee,
  100: #c5c6cb,
  200: #9ea1a9,
  300: #7d818c,
  400: #5c616f,
  500: #0e576b,
  600: #353a48,
  700: #2d323e,
  800: #262933,
  900: #1e2129,
  A100: #c5c6cb,
  A200: #9ea1a9,
  A400: #0e576b,
  A700: #2d323e,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$mat-lime: (
  50: #f9fbe7,
  100: #f0f4c3,
  200: #e6ee9c,
  300: #dce775,
  400: #d4e157,
  500: #cddc39,
  600: #258ba5,
  700: #afb42b,
  800: #9e9d24,
  900: #827717,
  A100: #f4ff81,
  A200: #eeff41,
  A400: #c6ff00,
  A700: #aeea00,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

// Generate Fuse color classes for custom palettes
$custom_palettes: (
  fuse-white: $fuse-white,
  fuse-black: $fuse-black,
  fuse-navy: $fuse-navy,
);

@include fuse-color-classes($custom_palettes);

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------

// Angular Material typography
$typography: mat.define-typography-config($font-family: "Muli, Helvetica Neue, Arial, sans-serif",
    $title: mat.define-typography-level(20px, 32px, 600),
    $body-2: mat.define-typography-level(14px, 24px, 600),
    $button: mat.define-typography-level(14px, 14px, 600),
    $input: mat.define-typography-level(16px, 1.125, 400) // line-height must be unitless !!!,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
  );

// Setup the typography
@include mat.all-component-typographies($typography);

// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Import app and page component themes here to enable theming for them
@import "src/app/layout/components/chat-panel/chat-panel.theme";
@import "src/app/layout/components/toolbar/toolbar.theme";

//Bruno import dei singoli temi theme
@import "src/app/authentication/login/login.theme.scss";
@import "src/app/authentication/register/register.theme.scss";
@import "src/app/authentication/forgot-password/forgot-password.theme.scss";
@import "src/app/authentication/change-password/change-password.theme.scss";
@import "src/app/authentication/mail-confirm/mail-confirm.theme.scss";
@import "src/app/authentication/non-attivo/non-attivo.theme.scss";

@import "src/app/main/legal/legal.theme.scss";
@import "src/app/main/aziende/clienti/clienti.theme.scss";
@import "src/app/main/impostazioni/users/users.theme.scss";
@import "src/app/main/rubrica/rubrica.theme.scss";
@import "src/app/main/impostazioni/nazioni/nazioni.theme.scss";
@import "src/app/main/spese/listini/listini.theme.scss";
@import "src/app/main/spese/listino-cliente/listino-cliente.theme.scss";
@import "src/app/main/home/home.theme.scss";
@import "src/app/main/agenda/agenda.theme.scss";
@import "src/app/main/file-manager/file-manager.theme.scss";
@import "src/app/main/progetti/progetti.theme.scss";
@import "src/app/main/todo/todo.theme.scss";
@import "src/app/main/progetti/sottofasi/scrumboard.theme.scss";
@import "src/app/main/impostazioni/timesheet/timesheet.theme.scss";
@import "src/app/main/spese/voci-spesa/voci-spesa.theme.scss";
@import "src/app/main/spese/categorie-spese/categorie-spese.theme.scss";
@import "src/app/main/spese/aliquota-iva/aliquota-iva.theme.scss";
@import "src/app/main/spese/modalita-pagamento/modalita-pagamento.theme.scss";
@import "src/app/main/spese/caricamento-sdi/caricamento-sdi.theme.scss";
@import "src/app/main/contabilita/fatture/fatture.theme.scss";
@import "src/app/main/documentale/documentale.theme.scss";
//@import "src/app/main/contabilita/fatture-ricevute/fatture-ricevute.theme.scss";
//@import "src/app/main/contabilita/fatture-proposte/fatture-proposte.theme.scss";

@import "src/app/main/sbarea/sbarea.theme.scss";

//@import "src/app/main/contabilita/riconciliazione/riconciliazione.theme.scss";
@import "src/app/main/impostazioni/tags/tags.theme.scss";

// Define a mixin for easier access
@mixin components-theme($theme) {
  // Layout components
  @include chat-panel-theme($theme);
  @include toolbar-theme($theme);
  @include login-theme($theme);
  @include register-theme($theme);
  @include forgot-password-theme($theme);
  @include change-password-theme($theme);
  @include mail-confirm-theme($theme);
  @include non-attivo-theme($theme);

  @include sbarea-theme($theme);
  //@include marchi-theme($theme);
  //@include brevetti-theme($theme);
  //@include design-theme($theme);
  @include legal-theme($theme);
  @include clienti-theme($theme);
  @include documentale-theme($theme);
  @include nazioni-theme($theme);
  @include users-theme($theme);
  @include listini-theme($theme);
  @include rubrica-theme($theme);
  @include home-theme($theme);
  @include agenda-theme($theme);
  @include file-manager-theme($theme);
  @include progetti-theme($theme);
  @include todo-theme($theme);
  @include scrumboard-theme($theme);
  @include timesheet-theme($theme);
  @include voci-spesa-theme($theme);
  @include categorie-spese-theme($theme);
  @include modalita-pagamento-theme($theme);
  @include caricamento-sdi-theme($theme);
  //@include riconciliazione-theme($theme);
  @include aliquota-iva-theme($theme);
  @include tags-theme($theme);
  @include fatture-theme($theme);
  @include listino-cliente-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette($fuse-navy);
$default-accent-palette: mat.define-palette($mat-lime, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$theme: mat.define-light-theme($default-primary-palette, $default-accent-palette, $default-warn-palette);

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {
  // Create an Angular Material theme from the $theme map
  @include mat.all-component-themes($theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($theme);

  // Apply the theme to the user components
  @include components-theme($theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
  );

  @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a yellow light theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$yellow-light-theme-primary-palette: mat.define-palette($fuse-navy, 600, 400, 700);
$yellow-light-theme-accent-palette: mat.define-palette(mat.$yellow-palette, 600, 400, 700);
$yellow-light-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$yellow-light-theme: mat.define-light-theme($yellow-light-theme-primary-palette,
    $yellow-light-theme-accent-palette,
    $yellow-light-theme-warn-palette);

// Add ".theme-yellow-light" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-yellow-light {
  // Generate the Angular Material theme
  @include mat.all-component-themes($yellow-light-theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($yellow-light-theme);

  // Apply the theme to the user components
  @include components-theme($yellow-light-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (primary: $yellow-light-theme-primary-palette,
    accent: $yellow-light-theme-accent-palette,
    warn: $yellow-light-theme-warn-palette,
  );

  @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a blue-gray dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$blue-gray-dark-theme-primary-palette: mat.define-palette(mat.$blue-palette);
$blue-gray-dark-theme-accent-palette: mat.define-palette(mat.$blue-gray-palette);
$blue-gray-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$blue-gray-dark-theme: mat.define-dark-theme($blue-gray-dark-theme-primary-palette,
    $blue-gray-dark-theme-accent-palette,
    $blue-gray-dark-theme-warn-palette);

// Add ".theme-blue-gray-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-blue-gray-dark {
  // Generate the Angular Material theme
  @include mat.all-component-themes($blue-gray-dark-theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($blue-gray-dark-theme);

  // Apply the theme to the user components
  @include components-theme($blue-gray-dark-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (primary: $blue-gray-dark-theme-primary-palette,
    accent: $blue-gray-dark-theme-accent-palette,
    warn: $blue-gray-dark-theme-warn-palette,
  );

  @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a pink dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme($pink-dark-theme-primary-palette,
    $pink-dark-theme-accent-palette,
    $pink-dark-theme-warn-palette);

// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-pink-dark {
  // Generate the Angular Material theme
  @include mat.all-component-themes($pink-dark-theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($pink-dark-theme);

  // Apply the theme to the user components
  @include components-theme($pink-dark-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (primary: $pink-dark-theme-primary-palette,
    accent: $pink-dark-theme-accent-palette,
    warn: $pink-dark-theme-warn-palette,
  );

  @include fuse-color-classes($palettes);
}

.italic {
  font-style: italic;
  color: gray;
}

//Bruno

.mat-header-cell {
  color: #0e576b !important;
}

.mat-header-row {
  background-color: #fffae4 !important;
}

.mat-sort-header-container {
  font-weight: bold;
}

/*
.mat-row:nth-child(even){
     background-color:red;
    }
*/
.mat-row:nth-child(odd) {
  //background-color: #fbfbfb;
  //background-color: #e9e9e9;
  background-color: #e5faff;
}

/*
.mat-row {
  margin-top: 10px;
  margin-bottom: 10px;
  border-top-width: 1px;
  border-top-style: solid;
} */

.mat-row:hover {
  //background-color: whitesmoke;
  background-color: #c5f4ff;
  //border-top-color: #06bae6;
  //border-bottom-color: #06bae6;
}

body.theme-default mat-row,
body.theme-default mat-header-row,
body.theme-default mat-footer-row,
body.theme-default th.mat-header-cell,
body.theme-default td.mat-cell,
body.theme-default td.mat-footer-cell {
  //border-top-color: #96e0f2;
  border-bottom-color: #96e0f2;
}

.mat-paginator-outer-container {
  height: 40px;
  background-color: #fffae4;
}

.recap-title {
  font-weight: 600;
  font-style: italic;
  font-size: medium;
  padding: 2px 0px;
}

ngx-mat-select-search {
  .mat-datepicker-content {
    box-shadow: none !important;
  }
}

// No margin bottom for mat-form-field
.no-margin .mat-form-field-wrapper {
  margin-bottom: -1.25em !important;
}

.no-padding .mat-form-field-wrapper {
  padding-bottom: 0px !important;
}

.pointer {
  cursor: pointer;
}

.download:hover {
  color: blue;
  text-decoration: underline;
}

.pff {
  padding-bottom: 1.34375em;
}

@mixin snackbar($theme) {
  $primary: mat.get-color-from-palette(map-get($theme, primary));
  $primary-text: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
  $warn: mat.get-color-from-palette(map-get($theme, warn));
  $warn-text: mat.get-color-from-palette(map-get($theme, warn), default-contrast);

  .snackbar-style-succes {
    color: $primary-text !important;
    background-color: green !important;

    .mat-simple-snackbar-action {
      color: white !important;
    }
  }

  .snackbar-style-succes .mat-simple-snackbar-action {
    color: $primary-text;
  }

  .snackbar-style-error {
    color: $warn-text !important;
    background-color: $warn !important;

    .mat-simple-snackbar-action {
      color: white !important;
    }
  }

  .snackbar-style-error .mat-simple-snackbar-action {
    color: $warn-text;
  }
}

@include snackbar($theme);

//Bruno spostato su stile generale per bug
.drag-box {
  padding: 10px 10px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  /* cursor: move; */
  background: white;
  font-size: 14px;
}

.cdk-drag-preview {
  @extend .drag-box;
  box-sizing: border-box;
  border-radius: 4px;
  opacity: 0.5;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
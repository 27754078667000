@use '@angular/material' as mat;
@mixin change-password-theme($theme) {
  $background: map-get($theme, background);
  $is-dark: map-get($theme, is-dark);

  change-password {
    #change-password {
      #change-password-form-wrapper {
        #change-password-form {
          @if ($is-dark) {
            background: mat.get-color-from-palette($fuse-navy, 600);
          } @else {
            background: map-get($background, card);
          }
        }
      }
    }
  }
}

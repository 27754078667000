// Import Fuse core library
@import "@fuse/scss/core";

// Import app.theme.scss
@import "app/app.theme";

@import "node_modules/angular-calendar/scss/angular-calendar";

.testoGrigio {
  color: #0d586b;
  display: contents;
}

.tags {
  .tag {
    font-size: 11px;
    border-radius: 2px;
    margin: 8px 4px 0 0;
    padding: 3px 8px;
    background-color: rgba(0, 0, 0, 0.08);

    .tag-color {
      width: 8px;
      height: 8px;
      margin-right: 8px;
      border-radius: 50%;
    }
  }
}

mat-chip-list {
  .tag-color {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 50%;
  }
}

/* Icone per file e documenti */
.type-icon {
  &.jpg {
    &:before {
      content: "image";
      color: #76adec;
    }
  }

  &.png {
    &:before {
      content: "image";
      color: #76adec;
    }
  }

  &.pdf {
    &:before {
      content: "picture_as_pdf";
      color: #e61e1e;
    }
  }

  &.doc {
    &:before {
      content: "description";
      color: #1565c0;
    }
  }

  &.ocx {
    &:before {
      content: "description";
      color: #1565c0;
    }
  }

  &.xls {
    &:before {
      content: "insert_chart";
      color: #4caf50;
    }
  }

  &.lsx {
    &:before {
      content: "insert_chart";
      color: #4caf50;
    }
  }
}
